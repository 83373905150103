
import './App.css';
import React from 'react';
const data = require('./words.json');

function App() {
  return (
    <div className="App">
      <header>
        <link 
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" 
          rel="stylesheet" 
          integrity="sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx" 
          crossOrigin="anonymous"/>
        <Greeting />
      </header>
      <Solver />
    </div>
  );
}

function Greeting() {
  return(
    <h1 className='greeting'>
      Wordle Solver!
    </h1>
  );
}

class Solver extends React.Component{
  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.genWords = this.genWords.bind(this);
    this.state = {
      words: data,
      place1: '',
      place1Not: '',
      place2: '',
      place2Not: '',
      place3: '',
      place3Not: '',
      place4: '',
      place4Not: '',
      place5: '',
      place5Not: '',
      notChars: ''
    }
  }

  handleChange(e){
    const target = e.target;
    const value = target.value.toLowerCase();
    const name = target.name;
    const current = this.state;
    current[name] = value;
    this.genWords(current);
  }

  genWords(current, name, value){
    const newWords = [];
    data.forEach(element => {
      const word = element.value.toLowerCase();
      let wordNotMatch = false;
      [...current.notChars].forEach(char => {        
        if(word.includes(char)){
          wordNotMatch = true;
        }
      });
      [...current.place1Not].forEach(char =>{
        if(!word.includes(char) || char === word[0]){
          wordNotMatch = true;
        }
      });
      [...current.place2Not].forEach(char =>{
        if(!word.includes(char) || current.place2Not === word[1]){
          wordNotMatch = true;
        }
      });
      [...current.place3Not].forEach(char =>{
        if(!word.includes(char) || current.place3Not === word[2]){
          wordNotMatch = true;
        }
      });
      [...current.place4Not].forEach(char =>{
        if(!word.includes(char) || current.place4Not === word[3]){
          wordNotMatch = true;
        }
      });
      [...current.place5Not].forEach(char =>{
        if(!word.includes(char) || current.place5Not === word[4]){
          wordNotMatch = true;
        }
      });
      
      if(!(current.place1 === word[0] || current.place1 === '')){
        wordNotMatch = true;
      }
      if(!(current.place2 === word[1] || current.place2 === '')){
        wordNotMatch = true;
      }
      if(!(current.place3 === word[2] || current.place3 === '')){
        wordNotMatch = true;
      }
      if(!(current.place4 === word[3] || current.place4 === '')){
        wordNotMatch = true;
      }
      if(!(current.place5 === word[4] || current.place5 === '')){
        wordNotMatch = true;
      }
      if(!wordNotMatch){
        newWords.push(element);
      }
    }, this.setState({[name]:value, words: newWords}));
  }

  wordNotInPlace(placeChar, wordChar){
    if(!(placeChar === wordChar || placeChar === '')){
      return true;
    }
    return false;
  }

  charInWordButNotPlace(notChar, word, place){
    if(!word.includes(notChar) || notChar === word[place]){
      return true;
    }
    return false;
  }

  render(){
    const data = this.state.words;
    const numWords = data.length;
    let cntTxt = 'There are 0 potential words.';
    if(numWords !== 0){cntTxt = 'There are '+numWords.toString()+' potential words.'; }
    return(
      <div className='container'>
        <div className='row'>
          <div className='col border'>
            <div className='row border bg-secondary'>
              <h5>Enter the characters that are not in the word.</h5>
              <label>Not characters: <input name='notChars' value={this.state.notChars} onChange={this.handleChange} /></label>
              <p/>
            </div>
            <div className='row border bg-success'>
              <h5>Enter the characters that you know as well as their place.</h5>
              <p><label>Character in place 1: <input name='place1' value={this.state.place1} onChange={this.handleChange} /></label></p>
              
              <p><label>Character in place 2: <input name='place2' value={this.state.place2} onChange={this.handleChange} /></label></p>
              
              <p><label>Character in place 3: <input name='place3' value={this.state.place3} onChange={this.handleChange} /></label></p>
              
              <p><label>Character in place 4: <input name='place4' value={this.state.place4} onChange={this.handleChange} /></label></p>
              
              <p><label>Character in place 5: <input name='place5' value={this.state.place5} onChange={this.handleChange} /></label></p>
            </div>  
              
          </div>
          <div className='col border bg-warning'>
            <h5>Known characters but in the wrong place.</h5>
            <p><label>Character not in place 1 but in word: <input name='place1Not' value={this.state.place1Not} onChange={this.handleChange}/></label></p>
            <p><label>Character not in place 2 but in word: <input name='place2Not' value={this.state.place2Not} onChange={this.handleChange}/></label></p>
            <p><label>Character not in place 3 but in word: <input name='place3Not' value={this.state.place3Not} onChange={this.handleChange}/></label></p>
            <p><label>Character not in place 4 but in word: <input name='place4Not' value={this.state.place4Not} onChange={this.handleChange}/></label></p>
            <p><label>Character not in place 5 but in word: <input name='place5Not' value={this.state.place5Not} onChange={this.handleChange}/></label></p>
          </div>
          <div className='col border'>
            <h5>Word list</h5>
                  <p>{cntTxt}</p>
                  <p>Top 10 words:</p>
                  <ol>        
                    {data.slice(0,10).map((word) => 
                      <li key={word.id}>{word.value}</li>
                    )}
                  </ol>
          </div>
        </div>      
      </div>
      );
  }
}


export default App;
